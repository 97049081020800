$(document).on("click", ".load-modal", e => {
  e.preventDefault();

  const currentTarget = $(e.currentTarget);
  const url = currentTarget.attr("data-url");

  if (!url) {
    throw new Error("The .load-modal class triggers a backend-driven modal, but no URL was provided.");
  }

  const loadingLabel = currentTarget.attr("data-disable-with") || "Loading...";
  const label = currentTarget.html();

  // Prevent parent popover from becoming sticky
  currentTarget.closest('[data-toggle="popover"]').popover('hide');
  // Prevent child popover from becoming sticky
  currentTarget.find('[data-toggle="popover"]').popover('hide');

  currentTarget.html(loadingLabel);
  currentTarget.addClass("disabled");
  currentTarget.prop("disabled", true);

  $.ajax({
    url,
    success: data => {
      const modal = $(data);

      if (currentTarget.attr("data-modal-container")) {
        $(currentTarget.attr("data-modal-container")).append(modal);
      } else {
        $("body").append(modal);
      }

      modal.on("hidden.bs.modal", () => modal.remove());

      if (window.ReactOnRails) {
        ReactOnRails.reactOnRailsPageLoaded();
      }

      modal.modal({ show: true, backdrop: 'static', keyboard: false });
    },
    error: (jqXHR, textStatus, errorThrown) => {
      alert(`An error occurred. Please try again. The response was: ${textStatus} ${errorThrown}`)
    },
    complete: () => {
      currentTarget.html(label);
      currentTarget.removeClass("disabled");
      currentTarget.prop("disabled", false);
    }
  });

  if ($(".modal.show-modal").length) {
    $(".modal.show-modal").modal('show');
  }
});

// Hide modals before navigating away.
$(document).on('turbo:before-visit', ()=> {
  // We first remove the `fade` class to avoid a race condition
  // between the Turbo snapshot and the jQuery fade-out animation.
  $('.modal').removeClass('fade').modal('hide');
});
